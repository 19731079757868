import React, { Component } from 'react';



class FloatingNumber extends Component {
    render(){
        return (
            <div className="Floating_number">
                <a href="tel:0528634222">052-8634222</a>
            </div>
        )
    }
}
export default FloatingNumber;