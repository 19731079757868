import React, { Component } from 'react';
const qs = require('qs');
const axios = require('axios');
export class FloatingContact extends Component {

    state={
        email: "",
        phoneNumber: "",
        name: "",
        msg: "נשלח מיצרת קשר הראש העמוד",
        btnDisabled:false,
        sendMsg:'שלח'
    };

    changeName = (event) =>{
        this.setState({name: event.target.value})
    }
    changeEmail = (event) =>{
      this.setState({email: event.target.value})
    }
    changeMsg = (event) =>{
      this.setState
      ({msg: event.target.value})
    }
    changePhoneNumber = (event) =>{
      this.setState
      ({phoneNumber: event.target.value})
    }


    sendEmail =  async() =>{

        console.log(this.state);
        var data = JSON.stringify({
          name: this.state.name,
          phone: this.state.phoneNumber,
          msg: "From top section",
          email: this.state.email,
      });
      var config = {
        method: "post",
        url: "https://yof.co.il/mail", // Add "http://"
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

        
        axios(config)
        .then(resp=>{
          if(resp){
              this.setState({
                  btnDisabled:'disabled',
                  sendMsg:'ההודעה נשלחה'
              })
          }
        });
        
      }

    render(){
        return (
            <div className="FloatingContact d-flex p-2 bd-highlight">
                {/* <div className="relative text-align-right"> */}
                <form name="sentMessage2" id="contactForm2" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                        <div className="form-group rounded">
                            <input
                            type="email"
                            id="email2"
                            className="form-control"
                            placeholder="אימייל"
                            value={this.state.email}
                            onChange={this.changeEmail}
                            tabIndex="6"
                            />
                            <p className="help-block text-danger"></p>
                        </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group rounded">
                        <input
                          type="text"
                          id="name2"
                          className="form-control"
                          placeholder="שם מלא"
                          required="required"
                          value={this.state.name}
                          onChange={this.changeName}
                          tabIndex="5"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                <div className="row margin_o">
                  <div className="form-group rounded">
                        <input
                          type="phone"
                          id="phone2"
                          className="form-control"
                          placeholder="טלפון"
                          // required="required"
                          value={this.state.phoneNumber}
                          onChange={this.changePhoneNumber}
                          tabIndex="7"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                </div>

                  <div id="success2"></div>
                  <div className="row margin_o">
                    <button type="button" className="btn btn-custom btn-lg col-md-12" onClick={this.sendEmail} tabIndex="8" disabled={this.state.btnDisabled}>
                        {this.state.sendMsg}
                    </button>
                  </div>
                </form>
              

                        {/* <div><a href="tel:0528634222">טלפון 0528634222</a></div>
                       <div> <a href="mailto:ofrayosef1@gmail.com"> ofrayosef1@gmail.com דואר אלקטרוני</a></div> */}
                {/* </div> */}
            </div>
            )
    }
}

export default FloatingContact;