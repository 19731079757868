import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top align-right float-right">
        <div className="align-right row">
        <div className="col-md-6 hide_on_mobile">
          <div className="row">
            <div className="col-md-6">
                  <a className="navbar-brand page-scroll details_size phone_number_size" href="tel:0528634222">
                      052-8634222
                    </a>{" "}
            </div>
            <div className="col-md-6">
                  <a className="navbar-brand page-scroll details_size" href="tel:0528634222">
                      ofrayosef1@gmail.com
                    </a>{" "}
            </div>
            {/* <div className="col-md-12">
                    <a className="page-scroll details_size" href="mailto:ofrayosef1@gmail.com">
                    ofrayosef1@gmail.com
                    </a>{" "}
            </div> */}
          </div>
        </div>  
          <div className="navbar-header col-md-4">
            <button 
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            {/* <a className="navbar-brand page-scroll" href="#page-top">
              עורכת דין עופרה יוסף
            </a>{" "} */}

          </div>

          <div
            className="collapse navbar-collapse float-right col-md-3"
            id="bs-example-navbar-collapse-1"
            dir="rtl"
          >

          
            <ul id="align-right" className="nav navbar-nav navbar-right float-right">
              <li className="float-right">
                <a href="#features" className="page-scroll">
עמוד הבית
                </a>
              </li>
              <li className="float-right">
                <a href="#about" className="page-scroll">
אודות המשרד
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                עיסוקי המשרד
                </a>
              </li>
              {/* <li>
                <a href="#portfolio" className="page-scroll">
                  כתבות
                </a>
              </li> */}
              <li>
                <a href="#testimonials" className="page-scroll">
                  גישור ובוררות
                </a>
              </li>
              {/* <li>
                <a href="#team" className="page-scroll">
                  הצוות
                </a>
              </li> */}
              <li>
                <a href="#contact" className="page-scroll">
                  צור קשר
                </a>
              </li>
            </ul>
          </div>


          <div className="col-md-3 show_on_mobile">
          <div className="row">
            <div className="col-sm-6 col-xs-6">
                  <a className="page-scroll details_size" href="tel:0528634222">
                      0528634222
                    </a>{" "}
            </div>
            <div className="col-sm-6 col-xs-6 only_desktop">
                    <a className="page-scroll details_size" href="mailto:ofrayosef1@gmail.com">
                    ofrayosef1@gmail.com
                    </a>{" "}
            </div>
          </div>
        </div>  


        </div>
      </nav>
    );
  }
}

export default Navigation;
