import React, { Component } from 'react';

class FeaturesComponent extends Component {

    state ={
        mobile: false
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 990
            });
        }, false);
    }
    render(){



        return (
            <div className={this.state.isMobile ? "row" : ''}>
              {/* ? this.props.data.map((d,i) => ( */}
                           {this.props.first && this.props.second ?
            <span > 
                  <div  className={`col-xs-6  col-md-3 features_circle`}>
                    {" "}
                    <i className={this.props.first.icon}></i>
                    <h3>{this.props.first.title}</h3>
                    <p>{this.props.first.text}</p>
                  </div>
                  <div  className={`col-xs-6 col-md-3 features_circle`}>
                    {" "}
                    <i className={this.props.second.icon}></i>
                    <h3>{this.props.second.title}</h3>
                    <p>{this.props.second.text}</p>
                  </div>

</span>
              : "Loading..."}
            </div>
        )
    }
}
export default FeaturesComponent;