import React, { Component } from "react";
export class Header extends Component {
  render() {
    return (
      <header id="header" >
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-md-offset-2 intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  {/* <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    צור קשר
                  </a>{" "} */}
                </div>
              </div>
              <div className="row four_job only_desktop">
                {this.props.features ? this.props.features.reverse().map((feature, i) => (
                  <div key={`${i}_feature_title`} className="col-md-2 col-sm-2 col-xs-2 feature_title">
                    <span key={`${i}_title`} className="job_title">{feature.title}</span>
                  </div>
                )) : "Loading"}
              </div>



              <div className="row four_job only_mobile">
  {this.props.features ? this.props.features.reverse().map((feature, i) => (
    (i % 2 === 0) ? (
      <div className="col-sm-12 col-xs-12 mobile_features" key={`${i}_feature_wrapper`}>
        <div key={`${i}_feature_title`} className="col-md-6 col-sm-6 col-xs-6 feature_title">
          <span key={`${i}_title`} className="job_title">{feature.title}</span>
        </div>
        {(i + 1 < this.props.features.length) && (
          <div key={`${i + 1}_feature_title`} className="col-md-6 col-sm-6 col-xs-6 feature_title">
            <span key={`${i + 1}_title`} className="job_title">{this.props.features[i + 1].title}</span>
          </div>
        )}
      </div>
    ) : null
  )) : "Loading"}
</div>

{/* 
              <div class="row four_job only_mobile">

        <div class="col-sm-12 col-xs-12">            
  <div class="col-sm-6 col-xs-6 feature_title">
    <span class="job_title">דיני משפחה</span>
  </div>
  <div class="col-sm-6 col-xs-6 feature_title">
    <span class="job_title">צוואות</span>
  </div>
  </div>

</div> */}




            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
