import React, { Component } from "react";

export class Services extends Component {

  NeedNewRow(i){
    return (<div className="row">hello world: {i}</div>);
  }
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>עיסוקי המשרד</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
          
          {this.props.data ? this.props.data.map((abc,j)=>(
            <div key={`${j}_rows`} className="row">
              {abc.map((d,i)=>(
                     <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                
              ))}
            </div>
            
          )) : ""}


            {/* {this.props.data
              ? this.props.data.map((d, i) => (
                
                
                  <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"} */}
          </div>
        </div>
    );
  }
}

export default Services;
