import React, { Component } from "react";
import axios from "axios";
const qs = require("qs");
export class Contact extends Component {
    state = {
        email: "",
        name: "",
        msg: "",
        phoneNumber: "",
        success: "",
    };

    changeName = (event) => {
        this.setState({ name: event.target.value });
    };
    changeEmail = (event) => {
        this.setState({ email: event.target.value });
    };
    changeMsg = (event) => {
        this.setState({ msg: event.target.value });
    };
    changePhoneNumber = (event) => {
        this.setState({ phoneNumber: event.target.value });
    };

    sendEmail = async () => {
        console.log(this.state);

        var data = JSON.stringify({
            name: this.state.name,
            phone: this.state.phoneNumber,
            msg: this.state.msg,
            email: this.state.email,
        });
        var config = {
          method: "post",
          url: "https://yof.co.il/mail", // Add "http://"
          headers: {
              "Content-Type": "application/json",
          },
          data: data,
      };

        axios.request(config)
        .then((resp) => {
            if (resp) {
                this.setState({ success: "ההודעה נשלחה בהצלחה." });
            }
        });
    };

    componentDidMount() {}

    render() {
        return (
            <div>
                <div id="contact">
                    <div className="container">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="section-title">
                                    <h2>צור קשר</h2>
                                    {/* <p>ניצור איתכם קשר בהקדם.</p> */}
                                </div>
                                {/* <form
                                    name="sentMessage"
                                    id="contactForm"
                                    noValidate
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="אימייל"
                                                    // required="required"
                                                    value={this.state.email}
                                                    onChange={this.changeEmail}
                                                    tabIndex="2"
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder="שם מלא"
                                                    required="required"
                                                    value={this.state.name}
                                                    onChange={this.changeName}
                                                    tabIndex="1"
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="phone"
                                            id="phone"
                                            className="form-control"
                                            placeholder="טלפון"
                                            // required="required"
                                            value={this.state.phoneNumber}
                                            onChange={this.changePhoneNumber}
                                            tabIndex="3"
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>

                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="form-control"
                                            rows="4"
                                            placeholder="תוכן ההודעה"
                                            value={this.state.msg}
                                            onChange={this.changeMsg}
                                            required
                                            tabIndex="4"
                                        ></textarea>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div id="success">{this.state.success}</div>
                                    <button
                                        type="button"
                                        className="btn btn-custom btn-lg"
                                        onClick={this.sendEmail}
                                        tabIndex="4"
                                    >
                                        שלח
                                    </button>
                                </form> */}
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-1 contact-info">
                            <div className="contact-item">
                                <h3>פרטי יצירת קשר</h3>
                                <p
                                    geo={
                                        this.props.data
                                            ? this.props.data.address
                                            : "loading"
                                    }
                                >
                                    <span>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        כתובת
                                    </span>
                                    {this.props.data
                                        ? this.props.data.address
                                        : "loading"}
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a
                                        className="contact_font"
                                        href={`tel=${
                                            this.props.data
                                                ? this.props.data.phone
                                                : "loading"
                                        }`}
                                    >
                                        <span>
                                            <i className="fa fa-phone"></i>{" "}
                                            טלפון
                                        </span>{" "}
                                        {this.props.data
                                            ? this.props.data.phone
                                            : "loading"}

                                    
                                    </a>
                                </p>
                            </div>


                            <div className="contact-item">
                                <p>
                                    <a
                                        className="contact_font"
                                        href={`tel=${
                                            this.props.data
                                                ? this.props.data.phone
                                                : "loading"
                                        }`}
                                    >
                                            <span>
                                            <i className="fa fa-fax"></i>{" "}
                                            פקס
                                        </span>{" "}
                                        {this.props.data
                                            ? this.props.data.fax
                                            : "loading"}
                                    </a>
                                </p>
                            </div>




                            <div className="contact-item">
                                <p>
                                    <a
                                        className="contact_font"
                                        href={`mailto=${
                                            this.props.data
                                                ? this.props.data.email
                                                : "loading"
                                        }`}
                                    >
                                        <span>
                                            <i className="fa fa-envelope-o"></i>{" "}
                                            דואר אלקטרוני
                                        </span>{" "}
                                        {this.props.data
                                            ? this.props.data.email
                                            : "loading"}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                href={
                                                    this.props.data
                                                        ? this.props.data
                                                              .facebook
                                                        : "/"
                                                }
                                            >
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div className="container text-center">
                        <p>
                            &copy;  עורכת דין עופרה יוסף{" "}
                            {/* <a href="http://www.templatewire.com" rel="nofollow">
                TemplateWire
              </a> */}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
