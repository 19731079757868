import React, { Component } from 'react'

export class about extends Component {
  render() {
    return (
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/ofra.jpg" className="img-responsive ofra_img" alt="עורכת דין עופרה יוסף"/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>
                 עו"ד עופרה יוסף
                </h2>
                {this.props.data ? this.props.data.text.map((text,i) => (
                  <p key={`${i}_about`}>
                    {text}
                  </p>  
                )) : 'loading...'}
                {/* <h3>למה אנחנו?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
