import React, { Component } from "react";
import FeaturesComponent from './FeaturesComponent';
export class features extends Component {

  printFeatures(){
    var rows =[];
    if(!this.props.data) return '';
    for(let i=0;i<this.props.data.length;i= i+2){
      rows.push (
        <FeaturesComponent key={`FeaturesComponent_${i}`} first={this.props.data[i]} second={this.props.data[i+1]}/>
      )
    }
    return rows;
  }
  render() {
    return (
      
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>עיסוקי המשרד</h2>
          </div>
          <div className="row">
          {this.printFeatures()}
          
            {/* {this.props.data
              ? this.props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className={`col-xs-6 col-md-3 features_circle`}>
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."} */}
          </div>
        </div>
      </div>
    );
  }
}

export default features;
