import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faLowVision, faAdjust, faBold, faFont, faGlobe } from '@fortawesome/free-solid-svg-icons';
import './accessibilityStyle.css'; // Create and import a CSS file for styles

const CustomAccessibilityButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const applyAccessibilityFeature = (feature) => {
    switch (feature) {
      case 'screenReader':
        // Add screen reader functionality
        break;
      case 'grayscale':
        document.body.style.filter = 'grayscale(100%)';
        break;
      case 'invertedColors':
        document.body.style.filter = 'invert(100%)';
        break;
      case 'boldLinks':
        document.querySelectorAll('a').forEach(link => {
          link.style.fontWeight = 'bold';
        });
        break;
      case 'normal':
        document.body.style.filter = 'none';
        document.querySelectorAll('a').forEach(link => {
          link.style.fontWeight = 'normal';
        });
        break;
      case 'fontSizeNormal':
        document.body.style.fontSize = '100%';
        break;
      case 'fontSize125':
        document.body.style.fontSize = '125%';
        break;
      case 'fontSize150':
        document.body.style.fontSize = '150%';
        break;
      default:
        break;
    }
  };

  return (
    <div className="custom-accessibility-container" id="accessibility_container" style={{ display: 'block' }}>
      <button className="custom-accessibility-menu-icon" id="accessibility_menu_button" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faAccessibleIcon} />
      </button>
      {menuOpen && (
        <ul className="custom-accessibility-list">
          <li className="custom-accessibility-item">
            <button className="custom-close-button" onClick={() => applyAccessibilityFeature('normal')} id="close_accessibility_button" tabIndex="1">
              <FontAwesomeIcon className="custom-close-icon" icon={faTimes} title="Close Accessibility" />
            </button>
          </li>
          <li className="custom-accessibility-item">
            <button className="custom-accessibility-feature-button" onClick={() => applyAccessibilityFeature('screenReader')} id="screen_reader_button" tabIndex="1">
              <FontAwesomeIcon className="custom-feature-icon" icon={faLowVision} alt="Screen Reader" />
              התאמה לקורא מסך
            </button>
          </li>
          <li className="custom-accessibility-item">
            <button className="custom-accessibility-feature-button" onClick={() => applyAccessibilityFeature('grayscale')} id="grayscale_button" tabIndex="1">
              <FontAwesomeIcon className="custom-feature-icon" icon={faAdjust} alt="Grayscale" />
              התאמה לעיוורי צבעים
            </button>
          </li>
          <li className="custom-accessibility-item">
            <button className="custom-accessibility-feature-button" onClick={() => applyAccessibilityFeature('invertedColors')} id="inverted_colors_button" tabIndex="1">
              <FontAwesomeIcon className="custom-feature-icon" icon={faAdjust} alt="Inverted Colors" />
              התאמה לכבדי ראיה
            </button>
          </li>
          <li className="custom-accessibility-item">
            <button className="custom-accessibility-feature-button" onClick={() => applyAccessibilityFeature('boldLinks')} id="bold_links_button" tabIndex="1">
              <FontAwesomeIcon className="custom-feature-icon" icon={faBold} alt="Bold Links" />
              הדגשת קישורים
            </button>
          </li>
          <li className="custom-accessibility-item">
            <button className="custom-accessibility-feature-button" onClick={() => applyAccessibilityFeature('normal')} id="normal_view_button" tabIndex="1">
              <FontAwesomeIcon className="custom-feature-icon" icon={faFont} alt="Normal View" />
              תצוגה רגילה
            </button>
          </li>
          <li className="custom-font-size-options">
            <button className="custom-font-size-button" onClick={() => applyAccessibilityFeature('fontSizeNormal')} id="font_size_normal_button" tabIndex="1" title="כפתור חזרה לגודל טקסט מקורי">
              Aא
            </button>
            <button className="custom-font-size-button" onClick={() => applyAccessibilityFeature('fontSize125')} id="font_size_125_button" tabIndex="1" title="כפתור הגדלת טקסט">
              Aא
            </button>
            <button className="custom-font-size-button" onClick={() => applyAccessibilityFeature('fontSize150')} id="font_size_150_button" tabIndex="1" title="כפתור הגדלת טקסט למקסימום">
              Aא
            </button>
          </li>
          <li className="custom-accessibility-statement-item" id="accessibility_statement_item" style={{ display: 'list-item' }}>
            <button className="custom-accessibility-feature-button" id="accessibility_statement_button" tabIndex="1" onClick={() => window.open('https://example.com/accessibility.html', '_blank')}>
              <FontAwesomeIcon className="custom-feature-icon" icon={faGlobe} alt="Accessibility Statement" />
              <a href="https://example.com/accessibility.html" id="accessibility_statement_link" target="_blank" rel="noopener noreferrer" style={{ color: 'buttontext' }}>
                הצהרת נגישות אתר
              </a>
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default CustomAccessibilityButton;
